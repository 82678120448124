import React from 'react';

import FlagIconFactory from 'react-flag-icon-css';
import 'flag-icon-css/css/flag-icon.min.css';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

type CountryFlagProps = {
  code: string;
  className?: string;
};

export const CountryFlag = ({ code, className }: CountryFlagProps) =>
  code && <FlagIcon code={code.toLocaleLowerCase()} className={className} />;
