import { Metadata } from 'next';

import { LocaleKey, mainLocaleKey, localeKeys } from '@/constants/locales';

export const getLocaleFromPathname = (pathname: string): LocaleKey | undefined =>
  localeKeys.find((locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`);

export const getLocaleSpecificUrl = (localeKey: LocaleKey, pathname: string) => {
  const isMainLocaleKey = localeKey === mainLocaleKey;
  if (pathname.indexOf('/dashboard') === 0) {
    return pathname;
  }
  if (pathname === '/') {
    return isMainLocaleKey ? '/' : `/${localeKey}`;
  }
  for (const key of localeKeys) {
    if (pathname === `/${key}`) {
      return isMainLocaleKey ? '/' : `/${localeKey}`;
    }
    if (pathname.startsWith(`/${key}/`)) {
      return pathname.replace(`/${key}`, isMainLocaleKey ? '' : `/${localeKey}`);
    }
  }
  return isMainLocaleKey ? pathname : `/${localeKey}${pathname}`;
};

export const getMetaLanguages = (
  currentLocale: LocaleKey,
  basePath: string,
  pathname: string,
): Metadata['alternates'] => {
  const languages: Record<string, string> = {};
  const canonicalPathname = getLocaleSpecificUrl(currentLocale, pathname);

  localeKeys.forEach((k) => {
    const languagePathname = getLocaleSpecificUrl(k, pathname);
    languages[k] = languagePathname === '/' ? basePath : `${basePath}${languagePathname}`;
  });
  return {
    canonical: `${basePath}${canonicalPathname === '/' ? '' : canonicalPathname}`,
    languages,
  };
};
