// https://www.realtimecolors.com/?colors=dadcf6-245b8f-70a8db-3a4259-b42813&fonts=Poppins-Poppins

const styles = {
  colors: {
    text: {
      50: '#eaecfa',
      100: '#d6d8f5',
      200: '#adb1eb',
      300: '#848ae1',
      400: '#5b63d7',
      500: '#323ccd',
      600: '#2830a4',
      700: '#1e247b',
      800: '#141852',
      900: '#0a0c29',
      950: '#050615',
    },
    background: {
      50: '#ebf3fa',
      100: '#d6e6f5',
      200: '#adcdeb',
      300: '#85b4e0',
      400: '#5c9bd6',
      500: '#3382cc',
      600: '#2968a3',
      700: '#1f4e7a',
      800: '#143452',
      900: '#131326',
      950: '#131326',
    },
    primary: {
      50: '#ebf3fa',
      100: '#d6e6f5',
      200: '#adcdeb',
      300: '#85b4e0',
      400: '#5c9bd6',
      500: '#3382cc',
      600: '#2968a3',
      700: '#1f4e7a',
      800: '#143452',
      900: '#0a1a29',
      950: '#050d14',
    },
    secondary: {
      50: '#f0f1f5',
      100: '#e0e3eb',
      200: '#c1c7d7',
      300: '#a2aac3',
      400: '#848eae',
      500: '#65729a',
      600: '#515b7b',
      700: '#3c445d',
      800: '#282e3e',
      900: '#14171f',
      950: '#0a0b0f',
    },
    accent: {
      50: '#fdebe8',
      100: '#fad6d1',
      200: '#f5aea3',
      300: '#f08575',
      400: '#ec5c46',
      500: '#e73418',
      600: '#b92913',
      700: '#8a1f0f',
      800: '#5c150a',
      900: '#2e0a05',
      950: '#170502',
    },
  },
};

export const colors = styles.colors;
